import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const FaqPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <div>
      <section class="pt-6 pt-md-11 pb-9 pb-md-11 bg-dark">
        <div class="container-lg">
          <div class="row justify-content-center">
            <div class="col-md-10 col-lg-8 text-white text-center">

              <h6 class="text-uppercase text-success mb-5">
                FAQ
              </h6>

              <h2 class="display-3 mb-4">
                Frequently Asked Questions
              </h2>

              <p class="font-size-lg text-gray-500">
                Welcome fellow internet traveler! <br /> If you have any additional questions, please <Link to="/contact" className="text-warning" title="Reno Collective">reach out to us</Link>.
              </p>

            </div>
          </div>
        </div>
      </section>
      <div class="position-relative">
        <div class="shape shape-fluid-x shape-bottom text-dark">
          <div class="shape-img pb-8 pb-md-11">
            <svg viewBox="0 0 100 50" preserveAspectRatio="none"><path d="M0 0h100v25H75L25 50H0z" fill="currentColor"/></svg>
          </div>
        </div>
      </div>
      <section class="pt-10 pt-md-12">
        <div class="container-lg">
          <div class="row">
            <div class="col">

              <hr class="hr-sm border-warning mb-10 mb-md-12" />
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-md-4">


              <div class="position-sticky mb-8" style={{top:2+"rem"}}>


                <h1 class="mb-4">
                  About Us
                </h1>


                <p class="mb-0">
                  The who, what, when and where
                </p>

              </div>

            </div>
            <div class="col-md-7">


              <div class="list-group list-group-lg list-group-flush mt-n7 mb-9" id="faqData">
                <div class="list-group-item">


                  <a class="collapse-toggle font-size-lg font-weight-bold text-decoration-none text-reset" data-toggle="collapse" href="#faqDataOne" role="button" aria-expanded="true" aria-controls="faqDataOne">
                    Who runs Reno Collective?
                  </a>


                  <div class="collapse show" id="faqDataOne" data-parent="#faqData">
                    <p class="mt-2 mb-0">
                      The Reno Collective is 100% bootstrapped and locally-owned by four individuals who are also self-employed, active members of the community. It is run day-to-day with the help of our Community Manager and each of the members. Membership dues pay to keep Reno Collective operating.
                    </p>
                  </div>

                </div>
                <div class="list-group-item">


                  <a class="collapse-toggle font-size-lg font-weight-bold text-decoration-none text-reset" data-toggle="collapse" href="#faqDataTwo" role="button" aria-expanded="false" aria-controls="faqDataTwo">
                    How do I get the most out of my membership?
                  </a>


                  <div class="collapse" id="faqDataTwo" data-parent="#faqData">
                    <p class="mt-2 mb-0">
                      Participate. Participate. Participate. Introduce yourself. Ask questions, ask for feedback. Find out what other members are doing. Help each other out. Take part in virtual events, watch the videos. And little by little (it’s not magic and it doesn’t happen overnight) you will find yourself involved.
                    </p>
                  </div>

                </div>
                <div class="list-group-item">


                  <a class="collapse-toggle font-size-lg font-weight-bold text-decoration-none text-reset" data-toggle="collapse" href="#faqDataThree" role="button" aria-expanded="false" aria-controls="faqDataThree">
                    Who else is a member of the Reno Collective?
                  </a>


                  <div class="collapse" id="faqDataThree" data-parent="#faqData">
                    <p class="mt-2 mb-0">
                      Our members are a diverse group of independent workers, makers, thinkers, and doers. Freelancers, small business owners, side-hustlers, remote employees, WFH-ers, telecommuters, students. If you are looking for a member directory you need to become a member first!
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-md-4">


              <div class="position-sticky mb-8" style={{top:2+"rem"}}>


                <h1 class="mb-4">
                  Resources
                </h1>


                <p class="mb-0">
                  People, skills, perks, and space
                </p>

              </div>

            </div>
            <div class="col-md-7">


              <div class="list-group list-group-lg list-group-flush mt-n7 mb-9" id="faqAccount">
                <div class="list-group-item">


                  <a class="collapse-toggle font-size-lg font-weight-bold text-decoration-none text-reset" data-toggle="collapse" href="#faqAccountOne" role="button" aria-expanded="true" aria-controls="faqAccountOne">
                    Can I have access to the physical space?
                  </a>


                  <div class="collapse show" id="faqAccountOne" data-parent="#faqAccount">
                    <p class="mt-2 mb-0">
                      Yes! We are open again to new members! You can also check out more about <Link to="/space" title="Reno Collective">our space here</Link>.
                    </p>
                  </div>

                </div>
                <div class="list-group-item">


                  <a class="collapse-toggle font-size-lg font-weight-bold text-decoration-none text-reset" data-toggle="collapse" href="#faqAccountTwo" role="button" aria-expanded="false" aria-controls="faqAccountTwo">
                    Can I use the Reno Collective as my business and mailing address?
                  </a>


                  <div class="collapse" id="faqAccountTwo" data-parent="#faqAccount">
                    <p class="mt-2 mb-0">
                      Current members who have a 24/7 membership are welcome to use the Reno Collective as their business address and receive official mail. If you need a virtual mail service, we probably aren’t your best option.
                    </p>
                  </div>

                </div>
              </div>

            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-md-4">


              <div class="position-sticky mb-8" style={{top:2+"rem"}}>


                <h1 class="mb-4">
                  Billing
                </h1>


                <p class="mb-0">
                  How does this thing work?
                </p>

              </div>

            </div>
            <div class="col-md-7">


              <div class="list-group list-group-lg list-group-flush mt-n7 mb-0" id="faqBilling">
                <div class="list-group-item">


                  <a class="collapse-toggle font-size-lg font-weight-bold text-decoration-none text-reset" data-toggle="collapse" href="#faqBillingOne" role="button" aria-expanded="true" aria-controls="faqBillingOne">
                    Do I have to sign a long-term contract?
                  </a>


                  <div class="collapse show" id="faqBillingOne" data-parent="#faqBilling">
                    <p class="mt-2 mb-0">
                      Our memberships are billed on a month-to-month basis on the 25th of each month prior. Members are welcome to pre-pay if they desire. You will need to contact us at membership@renocollective.com to arrange.
                    </p>
                  </div>

                </div>
                <div class="list-group-item">


                  <a class="collapse-toggle font-size-lg font-weight-bold text-decoration-none text-reset" data-toggle="collapse" href="#faqBillingTwo" role="button" aria-expanded="false" aria-controls="faqBillingTwo">
                    What payment methods can I use?
                  </a>


                  <div class="collapse" id="faqBillingTwo" data-parent="#faqBilling">
                    <p class="mt-2 mb-0">
                      All of our billing is organized through an online system that accepts all major credit and debit cards. If you are having trouble setting up your account please contact us at membership@renocollective.com
                    </p>
                  </div>

                </div>
                <div class="list-group-item">


                  <a class="collapse-toggle font-size-lg font-weight-bold text-decoration-none text-reset" data-toggle="collapse" href="#faqBillingThree" role="button" aria-expanded="false" aria-controls="faqBillingThree">
                    How do I change my payment information?
                  </a>


                  <div class="collapse" id="faqBillingThree" data-parent="#faqBilling">
                    <p class="mt-2 mb-0">
                      Each member has their own online access to their billing account. All changes can be made there (ie: updating credit card details, changing address, etc). If you are having trouble accessing your account, please contact us at membership@renocollective.com.
                    </p>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

FaqPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const FaqPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <FaqPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

FaqPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default FaqPage

export const aboutPageQuery = graphql`
  query FaqPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
